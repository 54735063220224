import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default {
  state: {
    home_url: process.env.VUE_APP_HOME_URI,
    app_name: '...andLastly.io™',
    app_version: '0.0.10 | BETA',
    client_id: process.env.VUE_APP_CLIENT_ID,
    client_secret: process.env.VUE_APP_CLIENT_SECRET
  },

  getters: {
    homeUrl: state => {
      return state.home_url.replace(/\/?$/, '/');
    },

    appName: state => {
      return state.app_name;
    },

    appVersion: state => {
      return state.app_version;
    },

    clientId: state => {
      return state.client_id;
    },

    clientSecret: state => {
      return state.client_secret;
    }
  },

  mutations: {},
  actions: {}
};
