// import some moduldes
import toastr from "toastr";

// action types
export const ADD_BODY_CLASSNAME = "addBodyClassName";
export const REMOVE_BODY_CLASSNAME = "removeBodyClassName";
export const ADD_CLASSNAME = "addClassName";
// mutation types
export const SET_CLASSNAME_BY_POSITION = "setClassNameByPosition";
export const TOAST = "toast";

export default {
  state: {
    classes: {},
    buttonSpinnerClasses: "spinner spinner-light spinner-right pr-15",
    inputSpinnerClasses: "spinner spinner-sm spinner-success spinner-right",
    quillToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }]
    ]
  },

  getters: {
    getClasses: state => position => {
      if (typeof position !== "undefined") {
        return state.classes[position];
      }
      return state.classes;
    },
    buttonSpinner: state => state.buttonSpinnerClasses,
    inputSpinner: state => state.inputSpinnerClasses,
    quillToolbar: state => state.quillToolbar
  },

  actions: {
    [ADD_BODY_CLASSNAME](context, className) {
      document.body.classList.add(className);
    },

    [REMOVE_BODY_CLASSNAME](context, className) {
      document.body.classList.remove(className);
    },

    [ADD_CLASSNAME](context, payload) {
      context.commit(SET_CLASSNAME_BY_POSITION, payload);
    },

    [TOAST](context, payload) {
      // do some type casting
      let variant = "";
      if (typeof payload === "string") {
        // set the variant by decoding the message (some what)
        const search = ["Error", "error", "something went wrong", "problem"];
        let isIncluded = false;
        search.forEach(item => {
          if (isIncluded == false) isIncluded = payload.includes(item);
        });

        variant = isIncluded ? "error" : "success";

        // get the content
        payload = {
          content: payload
        };
      } else {
        variant = payload.variant ?? "success";
      }

      toastr.options = {
        closeButton: false,
        debug: false,
        newestOnTop: false,
        progressBar: false,
        positionClass: "toast-top-right",
        preventDuplicates: true,
        onclick: null,
        showDuration: "300",
        hideDuration: "1000",
        timeOut: payload.timeOut
          ? payload.timeOut
          : variant == "success"
          ? "4000"
          : "7000",
        extendedTimeOut: "1000",
        showEasing: "swing",
        hideEasing: "linear",
        showMethod: "fadeIn",
        hideMethod: "fadeOut"
      };

      toastr[variant](payload.content ?? "", payload.title ?? "");
    }
  },

  mutations: {
    [SET_CLASSNAME_BY_POSITION](state, payload) {
      const { position, className } = payload;
      if (!state.classes[position]) {
        state.classes[position] = [];
      }
      state.classes[position].push(className);
    }
  }
};
