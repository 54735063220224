// import some services and tools
import ApiService from "../api.service";
import { GET_DASHBOARD } from "./user.module";
import store from "./index";

// EXPORT MESSAGES
export const SAVE_MESSAGE = "saveMessage";
export const GET_MESSAGE = "getMessage";
export const GET_MESSAGES = "getMessages";
export const DELETE_MESSAGE = "deleteMessage";
export const VIEW_MESSAGE = "viewMessage";

// EXPORT MUTATIONS
export const ADD_MESSAGE = "addMessage";
export const REMOVE_MESSAGE = "removeMessage";
export const UPDATE_MESSAGE = "updateMessage";
export const SET_MESSAGES = "setMessages";

// used to identify api endpiont
const resourceName = "messages";

export default {
  state: {
    messages: []
  },

  getters: {
    messages: state => state.messages
  },

  actions: {
    [SAVE_MESSAGE]: (context, data) => {
      return new Promise((resolve, reject) => {
        const mode = data.mode;
        const message = data.message;

        // todo:  connect to apiService
        if (mode == "create") {
          ApiService.post(resourceName, message)

            .then(response => {
              // commit the data
              context.commit(ADD_MESSAGE, response.data);

              return resolve(response);
            })

            .catch(error => {
              return reject(error);
            })

            .finally(() => {
              // get the latest dashboard stats
              store.dispatch(GET_DASHBOARD);
            });
        } else if (mode == "edit") {
          // create a fake error message just for testing
          const messageID = data.message.ID;

          ApiService.put(`${resourceName}/${messageID}`, message)

            .then(response => {
              // commit data
              context.commit(UPDATE_MESSAGE, response.data);

              return resolve(response);
            })

            .catch(error => {
              return reject(error);
            })

            .finally(() => {
              // get the latest dashboard stats
              store.dispatch(GET_DASHBOARD);
            });
        }
      });
    },

    [GET_MESSAGE]: (_, ID) => {
      return new Promise((resolve, reject) => {
        ApiService.get(resourceName, ID)
          .then(response => {
            return resolve(response);
          })

          .catch(error => {
            return reject(error);
          });
      });
    },

    [GET_MESSAGES]: context => {
      return new Promise((resolve, reject) => {
        ApiService.get(resourceName, "")

          .then(response => {
            context.commit(SET_MESSAGES, response.data);
            return resolve(response.data);
          })

          .catch(error => {
            return reject(error);
          });
      });
    },

    [DELETE_MESSAGE]: (context, ID) => {
      return new Promise((resolve, reject) => {
        ApiService.delete(resourceName, ID)

          .then(response => {
            // remove item using mutation
            context.commit(REMOVE_MESSAGE, ID);
            return resolve(response.data);
          })

          .catch(error => {
            return reject(error);
          })

          .finally(() => {
            store.dispatch(GET_DASHBOARD);
          });
      });
    },

    [VIEW_MESSAGE]: (context, key) => {
      return new Promise((resolve, reject) => {
        ApiService.get(`${resourceName}/view`, key)

          .then(response => {
            return resolve(response.data);
          })

          .catch(error => {
            return reject(error);
          });
      });
    }
  },

  mutations: {
    [SET_MESSAGES]: (state, messages) => {
      if (messages) {
        messages.forEach(message => {
          state.messages.push(message);
        });
      }
    },

    [ADD_MESSAGE]: (state, message) => {
      state.messages.push(message);
    },

    [REMOVE_MESSAGE]: (state, messageID) => {
      const messageIndex = state.messages.findIndex(item => item.ID == messageID);

      state.messages.splice(messageIndex, 1);
    },

    [UPDATE_MESSAGE]: (state, message) => {
      // get the item to update
      const item = state.messages.find(item => item.ID === message.ID);

      // assign the item so that vuex can detect changes
      if (item) Object.assign(item, message);
    }
  }
};
