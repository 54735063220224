// import requirementsf

import ApiService from "../api.service";
import store from "./index";
import { GET_DASHBOARD } from "./user.module";

// export contants
export const SETUP_TRUSTEES = "setupTrustees";
export const UPDATE_TRUSTEE = "updateTrustee";
export const GET_TRUSTEES = "getTrustees";
export const GET_TRUSTEE = "getTrustee";
export const RESEND_INVITATION = "resentTrusteeInvitation";
export const ACCEPT_TRUSTEE = "acceptTrustee";
export const DECLINE_TRUSTEE = "declineTrustee";

// MUTATIONS
export const SET_TRUSTEES = "setTrustees";
export const SET_TRUSTEE = "setTrustee";

const resourceName = "trustees";

export default {
  state: {
    trustees: [],
    hasTrustees: undefined
  },

  getters: {
    trustees: state => state.trustees,
    hasTrustees: state => state.hasTrustees
  },

  actions: {
    [ACCEPT_TRUSTEE]: (context, id, data = {}) => {
      data.forward_url = context.getters.homeUrl + "unlock";

      return new Promise((resolve, reject) => {
        ApiService.put(`${resourceName}/${id}/accept`, data)

          .then(response => {
            return resolve(response);
          })

          .catch(error => {
            return reject(error);
          })

          .finally();
      });
    },

    [DECLINE_TRUSTEE]: (context, id, data = {}) => {
      data.forward_url = context.getters.homeUrl + "unlock";

      return new Promise((resolve, reject) => {
        ApiService.put(`${resourceName}/${id}/decline`, data)

          .then(response => {
            return resolve(response);
          })

          .catch(error => {
            return reject(error);
          });
      });
    },

    [GET_TRUSTEE]: (_, id) => {
      return new Promise((resolve, reject) => {
        ApiService.get(resourceName, id)

          .then(response => {
            return resolve(response);
          })

          .catch(error => {
            return reject(error);
          });
      });
    },

    [SETUP_TRUSTEES]: (context, data) => {
      data.forward_url = context.getters.homeUrl + resourceName + "/activate";
      return new Promise((resolve, reject) => {
        ApiService.post(`${resourceName}/setup`, data)

          .then(response => {
            context.commit(SET_TRUSTEES, response.data);
            return resolve(response);
          })

          .catch(error => {
            return reject(error);
          });
      });
    },

    [UPDATE_TRUSTEE]: (context, data) => {
      data.forward_url = context.getters.homeUrl + resourceName + "/activate";
      return new Promise((resolve, reject) => {
        ApiService.update(resourceName, data.id, data)
          .then(response => {
            context.commit(SET_TRUSTEE, response.data);
            return resolve(response);
          })

          .catch(error => {
            return reject(error);
          })

          .finally(() => {
            store.dispatch(GET_DASHBOARD);
          });
      });
    },

    [SET_TRUSTEES]: (context, trustees) => {
      context.commit(SET_TRUSTEES, trustees);
    },

    [RESEND_INVITATION]: (context, trustee_id) => {
      const params = {
        forward_url: context.getters.homeUrl + resourceName + "/activate"
      };
      return new Promise((resolve, reject) => {
        ApiService.post(
          `${resourceName}/${trustee_id}/resend-invitation`,
          params
        )

          .then(response => {
            return resolve(response);
          })

          .catch(error => {
            return reject(error);
          });
      });
    }
  },

  mutations: {
    [SET_TRUSTEES]: (state, trustees) => {
      trustees = trustees ?? [];
      state.hasTrustees = trustees.length > 0 ? true : false;
      state.trustees = trustees;
    },

    [SET_TRUSTEE]: (state, trustee) => {
      // get the item to update
      const item = state.trustees.find(item => item.id === trustee.id);

      // assign the item so that vuex can detect changes
      Object.assign(item, trustee);
    }
  }
};
