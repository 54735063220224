// import api

import ApiService from "../api.service";

export const SEARCH_TRUSTEE_ACCOUNT = "searchTrusteeAccount";
export const VERIFY_TRUSTEE_KEY = "verifyTrusteeKey";
export const UNLOCK_TRUSTEE = "unlockTrustee";
// mutations
const SET_LOCK_DATA = "setLockData";

// resource name, linked to api
const resourceName = "lock";

export default {
  state: {
    lock: {}
  },

  getters: {
    lockData: state => state.lock
  },

  setters: {},

  actions: {
    [UNLOCK_TRUSTEE]: (context, id) => {
      return new Promise((resolve, reject) => {
        ApiService.post(`${resourceName}/unlock/${id}`)

          .then(response => {
            return resolve(response);
          })

          .catch(error => {
            return reject(error);
          });
      });
    },
    [VERIFY_TRUSTEE_KEY]: (context, key) => {
      return new Promise((resolve, reject) => {
        ApiService.post(`${resourceName}/verify/${key}`, {})

          .then(response => {
            context.commit(SET_LOCK_DATA, response.data);

            return resolve(response);
          })

          .catch(error => {
            return reject(error);
          });
      });
    },
    [SEARCH_TRUSTEE_ACCOUNT]: (context, email) => {
      return new Promise((resolve, reject) => {
        ApiService.get(resourceName, "search", { email })

          .then(response => {
            return resolve(response);
          })

          .catch(error => {
            return reject(error);
          });
      });
    }
  },

  mutations: {
    [SET_LOCK_DATA]: (state, data) => {
      state.lock = data;
    }
  }
};
