import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import d from "dayjs";
import { GET_USER, DESTROY_USER } from "@/core/services/store/user.module";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const ACTIVATE = "activate";
export const FORGOT = "forgotPassword";
export const RESET = "resetPassword";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setAuth";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

// import

const state = {
  errors: null,
  authToken: null,
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.login(credentials)
        .then(response => {
          // console.log("Here what post returns", data);
          context.commit(SET_AUTH, response.data.access_token);
          const userID = JwtService.decodeToken().sub;

          // get user data (in the reponse, setup trustees)
          context.dispatch(GET_USER, userID);

          return resolve(response);
        })
        .catch(error => {
          return reject(error);
        });
    });
  },

  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
    context.dispatch(DESTROY_USER);
  },

  [FORGOT](context, email) {
    const data = {
      email,
      forward_url: context.getters.homeUrl + "reset/"
    };

    return new Promise((resolve, reject) => {
      ApiService.post("login/forgot", data)
        .then(response => {
          return resolve(response);
        })

        .catch(error => {
          return reject(error);
        });
    });
  },

  [RESET](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("login/reset", data)
        .then(response => {
          return resolve(response);
        })

        .catch(error => {
          return reject(error);
        });
    });
  },

  [REGISTER](context, credentials) {
    credentials.forward_url = context.getters.homeUrl + "activation";
    return new Promise((resolve, reject) => {
      ApiService.post("user", credentials)

        .then(response => {
          return resolve(response.data);
        })

        .catch(error => {
          return reject(error);
        });
    });
  },

  [ACTIVATE](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.put("user/activate", credentials)

        .then(response => {
          return resolve(response.data);
        })

        .catch(error => {
          return reject(error);
        });
    });
  },

  [VERIFY_AUTH](context) {
    const token = JwtService.decodeToken();
    if (token) {
      // check if JWT has expired.  If not, assume that is still valid.
      const exp = d.unix(token.exp);
      const tokenExpired = d().isAfter(exp);
      if (tokenExpired) {
        context.dispatch(LOGOUT);
      } else {
        // set the bearer token from the JWT token
        ApiService.setAuthHeaders();
      }
    } else {
      context.dispatch(LOGOUT);
    }
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_AUTH](state, token) {
    state.isAuthenticated = true;
    state.authToken = token;
    JwtService.saveToken(token);
    ApiService.setAuthHeaders();
  },

  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    ApiService.deleteAuthHeaders();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
