import ApiService from "../api.service";
import jwtService from "../jwt.service";
import { LOGOUT } from "./auth.module";
import { SET_TRUSTEES } from "./trustees.module";

// action types
export const UPDATE_USER = "updateUser";
export const UPDATE_PASSWORD = "updateUserPassword";

export const GET_USER = "getUser";
export const GET_DASHBOARD = "getUserDASHBOARD";
export const DESTROY_USER = "destroyUser"; //unsets the user in the store
export const UPLOAD_AVATAR = "uploadAvatar";
export const REMOVE_AVATAR = "removeAvatar";

// mutation types
export const SET_USER = "setUser";
export const SET_ACCOUNT_INFO = "setAccountInfo";
export const SET_DASHBOARD = "setUserDashboard";

const resourceName = "user";

const state = {
  user: {},

  user_account_info: {
    username: "nick84",
    email: "nick.watson@loop.com",
    language: "English",
    time_zone: "(GMT-11:00) Midway Island",
    communication: {
      email: true,
      sms: true,
      phone: false
    },
    verification: true
  },

  dashboard: {
    trusteesStatus: false,
    readyMessage: 0,
    draftMessages: 0,
    notifications: 0
  }
};

const getters = {
  currentUser(state) {
    return state.user;
  },

  isUserSet(state) {
    if (state.user.ID) {
      return true;
    } else {
      return false;
    }
  },

  currentUserAccountInfo(state) {
    return state.user_account_info;
  },

  dashboard(state) {
    return state.dashboard;
  }
};

const actions = {
  [UPDATE_USER](context, user) {
    return new Promise((resolve, reject) => {
      ApiService.update(resourceName, user.ID, user)

        .then(response => {
          context.commit(SET_USER, response.data);
          return resolve(response);
        })

        .catch(error => {
          // context.commit(SET_USER, "");
          return reject(error);
        });
    });
  },

  [UPDATE_PASSWORD](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.put(`${resourceName}/${data.ID}/password`, data)

        .then(response => {
          return resolve(response);
        })

        .catch(error => {
          return reject(error);
        });
    });
  },

  [UPLOAD_AVATAR](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.uploadFile(`${resourceName}/${data.ID}/avatar`, data.file)
        .then(response => {
          context.commit(SET_USER, response.data);
          return resolve(response);
        })

        .catch(error => {
          return reject(error);
        });
    });
  },

  [GET_USER](context, userID = null) {
    userID = userID ?? jwtService.decodeToken().sub;
    if (!userID) {
      return context.dispatch(LOGOUT);
    } else {
      return new Promise((resolve, reject) => {
        ApiService.get(resourceName, userID)

          .then(response => {
            // set the trustees (in trustees.module.js)
            context.dispatch(SET_TRUSTEES, response.data.trustees);

            // set user data
            context.commit(SET_USER, response.data);

            // set the dashboard
            context.commit(SET_DASHBOARD, response.data.dashboard);
            return resolve(response);
          })

          .catch(error => {
            context.dispatch(LOGOUT);
            return reject(error);
          });
      });
    }
  },

  [GET_DASHBOARD](context) {
    return new Promise((resolve, reject) => {
      ApiService.get(resourceName, `dashboard`)

        .then(response => {
          context.commit(SET_DASHBOARD, response.data);
          return resolve(response);
        })

        .catch(error => {
          return reject(error);
        });
    });
  },

  [DESTROY_USER](context) {
    context.commit(SET_USER, {});
  }
};

const mutations = {
  [SET_USER](state, user) {
    state.user = user;
  },

  [SET_ACCOUNT_INFO](state, user_account_info) {
    state.user_account_info = user_account_info;
  },

  [SET_DASHBOARD](state, dashboard) {
    state.dashboard = {
      trusteesStatus: dashboard.trustees_status,
      readyMessages: dashboard.ready_messages,
      draftMessages: dashboard.draft_messages,
      notifications: dashboard.notifications
    };
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
