import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import QS from "qs";
import store from "@/core/services/store";
import { LOGOUT } from "./store/auth.module";
import {
  SET_LAST_RESPONSE,
  SET_UPLOAD_PROGRESS,
  SET_API_BUSY
} from "./store/api.module";

export const AUTH_ERROR = "authentication failed";

const client_id = process.env.VUE_APP_CLIENT_ID;
const client_secret = process.env.VUE_APP_CLIENT_SECRET;
const api_uri = process.env.VUE_APP_SERVER_API_URI;
// const server_uri = process.env.VUE_APP_SERVER_URI;
const login_url = process.env.VUE_APP_SERVER_LOGIN_URI;

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = api_uri;
    Vue.axios.defaults.headers.common["Content-Type"] =
      "application/x-www-form-urlencoded";
  },

  resolveResponse(response) {
    const data = {
      data: response.data,
      status: response.status,
      message: response.message,
      type: response.status >= 200 && response.status < 300 ? "success" : null
    };

    store.dispatch(SET_LAST_RESPONSE, data);

    return data;
  },

  resolveError(error) {
    const data = {
      type: "error"
    };

    if (error.response) {
      data.message = error.response.data.message ?? error.message;
      data.status = error.response.status;
      data.errors = error.response.data.errors;
    } else if (error.message == "Network Error" && error.isAxiosError == true) {
      data.message = "Network Error.  The Server cannot be reached.";
      data.status = 500;
    } else if (error.data.error_description) {
      data.message = error.data.error_description;
      data.status = 500;
    } else {
      data.message = "Something went wrong.  Please contact admin.";
      data.status = 500;
    }

    store.dispatch(SET_LAST_RESPONSE, data);

    // if error is 401, make sure the user is logged out
    if (data.status === 401) {
      store.dispatch(LOGOUT);
    }
    return data;
  },
  /**
   * Set the default HTTP request headers
   */
  setAuthHeaders() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  deleteAuthHeaders() {
    delete Vue.axios.defaults.headers.common["Authorization"];
  },

  refreshAuthToken(refreshToken) {
    const url = login_url;
    const params = QS.stringify({
      grant_type: "refresh_token",
      refresh_token: refreshToken
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      auth: {
        username: client_id,
        password: client_secret
      }
    };

    store.dispatch(SET_API_BUSY, true);
    return new Promise((resolve, reject) => {
      Vue.axios
        .post(url, params, config)

        .then(data => {
          return resolve(data.data);
        })

        .catch(error => {
          return reject(error);
        })

        .finally(() => {
          store.dispatch(SET_API_BUSY, false);
        });
    });
  },

  login(credentials) {
    credentials.grant_type = "password";
    const params = QS.stringify(credentials);
    const url = login_url;
    const config = {
      auth: {
        username: client_id,
        password: client_secret
      }
    };
    store.dispatch(SET_API_BUSY, true);
    return new Promise((resolve, reject) => {
      Vue.axios
        .post(url, params, config)

        .then(response => resolve(this.resolveResponse(response)))

        .catch(error => reject(this.resolveError(error)))

        .finally(() => {
          store.dispatch(SET_API_BUSY, false);
        });
    });
  },

  uploadFile(resource, file) {
    // prepare teh file
    let formData = new FormData();
    formData.append("file", file);

    let config = {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: progressEvent => {
        const uploadProgress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        store.dispatch(SET_UPLOAD_PROGRESS, uploadProgress);
      }
    };

    store.dispatch(SET_API_BUSY, true);
    return new Promise((resolve, reject) => {
      Vue.axios
        .post(`${resource}`, formData, config)

        .then(data => {
          return resolve(this.resolveResponse(data));
        })

        .catch(error => {
          return reject(this.resolveError(error));
        })

        .finally(() => {
          store.dispatch(SET_API_BUSY, false);
        });
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  get(resource, slug, params = {}) {
    store.dispatch(SET_API_BUSY, true);
    return new Promise((resolve, reject) => {
      Vue.axios
        .get(`${resource}/${slug}`, { params })

        .then(data => {
          return resolve(this.resolveResponse(data));
        })

        .catch(error => {
          return reject(this.resolveError(error));
        })

        .finally(() => {
          store.dispatch(SET_API_BUSY, false);
        });
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    store.dispatch(SET_API_BUSY, true);
    return new Promise((resolve, reject) => {
      Vue.axios
        .post(`${resource}`, QS.stringify(params))
        .then(data => {
          return resolve(this.resolveResponse(data));
        })

        .catch(error => {
          return reject(this.resolveError(error));
        })

        .finally(() => {
          store.dispatch(SET_API_BUSY, false);
        });
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    store.dispatch(SET_API_BUSY, true);
    return new Promise((resolve, reject) => {
      Vue.axios

        .put(`${resource}/${slug}`, QS.stringify(params))

        .then(data => {
          return resolve(this.resolveResponse(data));
        })

        .catch(error => {
          return reject(this.resolveError(error));
        })

        .finally(() => {
          store.dispatch(SET_API_BUSY, false);
        });
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    store.dispatch(SET_API_BUSY, true);
    return new Promise((resolve, reject) => {
      Vue.axios
        .put(`${resource}`, QS.stringify(params))
        .then(data => {
          return resolve(this.resolveResponse(data));
        })

        .catch(error => {
          return reject(this.resolveError(error));
        })

        .finally(() => {
          store.dispatch(SET_API_BUSY, false);
        });
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, slug, params = {}) {
    store.dispatch(SET_API_BUSY, true);
    return new Promise((resolve, reject) => {
      Vue.axios
        .delete(resource + "/" + slug, params)
        .then(data => {
          return resolve(this.resolveResponse(data));
        })

        .catch(error => {
          return reject(this.resolveError(error));
        })

        .finally(() => {
          store.dispatch(SET_API_BUSY, false);
        });
    });
  }
};

export default ApiService;
