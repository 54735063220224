export const ID_TOKEN_KEY = "id_token";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const decodeToken = (token = null) => {
  // if token is not parsed, look for it in local storage
  token = token ? token : window.localStorage.getItem(ID_TOKEN_KEY);

  // do a validation test
  if (!token || token == "undefined" || token === undefined) return false;

  // verify jsontoken
  try {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (err) {
    // do nothing
    return;
  }
};

export default { getToken, saveToken, destroyToken, decodeToken };
