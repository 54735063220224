export const SET_UPLOAD_PROGRESS = "setUploadProgress";
export const RESET_UPLOAD_PROGRESS = "resetUploadProgress";
export const SET_LAST_RESPONSE = "setLastResponse";
export const SET_API_BUSY = "setAPIBusy";

export default {
  state: {
    uploadProgress: 0,
    lastResponse: null,
    apiBusy: false
  },

  getters: {
    uploadProgress: state => state.uploadProgress,

    lastApiResponse: state => state.lastResponse,

    apiBusy: state => state.apiBusy
  },

  actions: {
    [SET_UPLOAD_PROGRESS](context, uploadProgress) {
      context.commit(SET_UPLOAD_PROGRESS, uploadProgress);

      // if we're finished set the upload progress to 0 after 2 seconds
      if (uploadProgress == 100) {
        context.dispatch(RESET_UPLOAD_PROGRESS);
      }
    },

    [SET_LAST_RESPONSE](context, lastResponseData) {
      context.commit(SET_LAST_RESPONSE, lastResponseData);
    },

    [RESET_UPLOAD_PROGRESS](context) {
      setTimeout(() => {
        context.commit(SET_UPLOAD_PROGRESS, 0);
      }, 2500);
    },

    [SET_API_BUSY](context, toggle) {
      context.commit(SET_API_BUSY, toggle);
    }
  },

  mutations: {
    /**
     * Sets the upload progress in a file upload api call
     * @param {*} state
     * @param {*} uploadProgress
     */
    [SET_UPLOAD_PROGRESS](state, uploadProgress) {
      state.uploadProgress = uploadProgress;
    },

    /**
     * Sets the last response from an api call
     * @param {*} state
     * @param {*} lastResponseData
     */
    [SET_LAST_RESPONSE](state, lastResponseData) {
      state.lastResponse = lastResponseData;
    },

    /**
     * Set the state the api (if busy set to true.  When finished set to false)
     * @param {*} state
     * @param {*} toggle
     */
    [SET_API_BUSY](state, toggle) {
      state.apiBusy = toggle;
    }
  }
};
