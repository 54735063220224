import ApiService from "../api.service";

export const GET_NOTIFICATIONS = "notifications_get";
export const TOGGLE_NOTIFICATION = "notifications_toggle";
// private constants
const SET_NOTIFICATIONS = "set_notifications";

const resourceName = "notifications";

export default {
  state: {
    notifications: []
  },

  getters: {
    notifications: state => state.notifications,

    unreadNotifications: state => {
      var obj = [];
      state.notifications.forEach(element => {
        if (element.status === 0) obj.push(element);
      });

      return obj;
    }
  },

  actions: {
    [GET_NOTIFICATIONS]: context => {
      return new Promise((resolve, reject) => {
        ApiService.get(`${resourceName}`, "")

          .then(response => {
            // commit the data to state
            context.commit(SET_NOTIFICATIONS, response.data);

            // return the data
            return resolve(response);
          })

          .catch(error => {
            return reject(error);
          });
      });
    },

    [TOGGLE_NOTIFICATION]: (context, id) => {
      return new Promise((resolve, reject) => {
        ApiService.put(`${resourceName}/${id}/toggle`)

          .then(response => {
            context.commit(TOGGLE_NOTIFICATION, id);
            return resolve(response.data);
          })

          .catch(error => {
            return reject(error);
          });
      });
    }
  },

  mutations: {
    [SET_NOTIFICATIONS]: (state, notifications) => {
      state.notifications = notifications;
    },

    [TOGGLE_NOTIFICATION]: (state, notification_id) => {
      var item = state.notifications.find(item => item.id === notification_id);

      // update the notification
      item.status = item.status === 0 ? 1 : 0;
    }
  }
};
