// import plguins
import Vue from "vue";
import Vuex from "vuex";

// import plugins
import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import user from "./user.module";
import api from "./api.module";
import app from "./app.module";
import trustees from "./trustees.module.js";
import lock from "./lock.module.js";
import messages from "./message.module.js";
import notifications from "./notifications.module.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    api,
    auth,
    htmlClass,
    config,
    breadcrumbs,
    user,
    app,
    trustees,
    lock,
    messages,
    notifications
  }
});
