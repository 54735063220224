import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/trustees",
          name: "trustees",
          component: () => import("@/view/pages/trustees/Trustees.vue")
        },
        {
          path: "/trustees/setup",
          name: "trustees.setup",
          component: () => import("@/view/pages/trustees/TrusteesSetup.vue")
        },
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/dashboard/Dashboard.vue")
        },
        {
          path: "/messages",
          name: "messages",
          component: () => import("@/view/pages/messages/Messages.vue")
        },
        {
          path: "/messages/create",
          name: "messages.create",
          component: () => import("@/view/pages/messages/Message.Editor.vue")
        },

        {
          path: "/messages/:id",
          name: "messages.single",
          component: () => import("@/view/pages/messages/Message.Editor.vue")
        },

        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/profile/Profile.vue")
        }
      ]
    },

    {
      path: "/",
      component: () => import("@/view/layout/PublicLayout.vue"),
      children: [
        {
          path: "unlock",
          name: "public.home",
          component: () => import("@/view/pages/public/Lock.vue")
        },
        {
          path: "trustees/activate/:key",
          name: "public.trustee.activate",
          component: () => import("@/view/pages/public/ActivateTrustee.vue")
        },
        {
          path: "/messages/view/:key",
          name: "messages.view",
          component: () => import("@/view/pages/messages/Message.View.vue")
        }
      ]
    },

    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },

    {
      path: "/",
      component: () => import("@/view/pages/auth/Login"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "activation",
          path: "/activation",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "activation.key",
          path: "/activation/:key",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "reset",
          path: "/reset/:key",
          component: () => import("@/view/pages/auth/Login")
        }
      ]
    },

    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
